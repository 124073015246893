/**
 * Debugging help
 */
.s1-debug-ad {
    background: purple;
    color: white;
    display: none;
    font-family: monospace;
    opacity: 0.7;
    padding: 1rem;
    position: absolute;
}

.debug .s1-debug-ad {
    display: block;
}

.debug .s1-ad {
    background: orange;
}

/**
 * an unknown ad
 */
.s1-ad-name-undefined.s1-ad-undefined.s1-placeholder-type-undefined {
    display: none;
}

/**
 * Styles for ads in articles
 */
 .article-section-container .article-section-main-area-container .s1-ad {
    margin-top: 24px;
    margin-bottom: 24px;
 }

/**
 * Style for ad containers, to prevent ads from being too wide (We cannot use overflow:hidden)
 */
.s1-ad-container{
    contain: paint;
}

#sdgSlotContainer-stickyfooter {
    bottom: calc(60px + env(safe-area-inset-bottom)) !important;
    box-shadow: none !important;
}

@media (min-width: 767px) {
    #article-bottom-navigation-container #sdgSlotContainer-stickyfooter {
        bottom: calc(100px + env(safe-area-inset-bottom)) !important;
    }
}

@media (min-width: 480px) and (max-width: 766px) {
    #article-bottom-navigation-container #sdgSlotContainer-stickyfooter {
        bottom: calc(80px + env(safe-area-inset-bottom)) !important;
    }
}

/**
 * Ad specific styles
 */
.s1-ad-name-sky,
.s1-debug-ad-name-sky {
    position: absolute;
}
/**
* Right sky,
* needs overflow-hidden, because otherwise a horizontal scroll appears s
*/
.s1-ad-sky {
    padding-left: 24px;
    overflow-x: hidden;
}
/**
* Left sky
*/
.s1-ad-sky2 {
    padding-right: 24px;
}

/**
 * CLS protections
 *
 * On ssr, we do not have the correct placement names, because the
 * user's device is unknown.
 *
 * | device  | SSR         | client    | min-height |
 * | ---     | ---         | ---       | ---        |
 * | desktop | BANNER      | null      |            |
 * | mobile  | BANNER      | topmobile | 150        |
 * | desktop | BILLBOARD   | banner    | 250        |
 * | mobile  | BILLBOARD   | null      |            |
 * | desktop | EVENT_TOP   | banner    | 250        |
 * | mobile  | EVENT_TOP   | topmobile | 150        |
 * | desktop | INPAGE      | posterad  | 250        |
 * | mobile  | INPAGE      | null      |            |
 * | desktop | MREC        | rectangle | 250        |
 * | mobile  | MREC        | topmobile | 250        |
 * | desktop | SKY         | sky       |            |
 * | mobile  | SKY         | null      |            |
 * | desktop | SUPERBANNER | banner    | 250        |
 * | mobile  | SUPERBANNER | null      |            |
 * | desktop | TICKERFEED  | rectangle | 250        |
 * | mobile  | TICKERFEED  | topmobile | 150        |
 */

/**
 * All min-heights +18px for the ad label "Anzeige"
 */
html {
    --ad-label-height: 18px;
}

.s1-ad-name-banner,
.s1-device-desktop .s1-placeholder-type-BILLBOARD,
.s1-device-desktop .s1-placeholder-type-EVENT_TOP,
.s1-device-desktop .s1-placeholder-type-SUPERBANNER {
    min-height: calc(250px + var(--ad-label-height));
}

.s1-ad-name-posterad,
.s1-device-desktop .s1-placeholder-type-TICKERFEED,
.s1-placeholder-type-INPAGE {
    min-height: calc(250px + var(--ad-label-height));
}

.s1-ad-name-rectangle,
.s1-placeholder-type-MREC,
.s1-ad-name-topmobile.s1-placeholder-type-MREC {
    min-height: calc(250px + var(--ad-label-height));
}

@media screen and (min-width: 1113px) {
    .s1-placeholder-type-MREC {
        min-height: calc(287px + var(--ad-label-height));
    }
}

.s1-ad-name-topmobile,
.s1-device-mobile .s1-placeholder-type-BANNER,
.s1-device-mobile .s1-placeholder-type-EVENT_TOP,
.s1-device-mobile .s1-placeholder-type-TICKERFEED {
    min-height: calc(150px + var(--ad-label-height));
}

/* This rule comes on the client side, so there is a small delay between 250px (MREC) to this specific rule 600px */
.s1-device-mobile .s1-ad-mobile-id-topmobile2,
.s1-ad-name-topmobile.s1-ad-topmobile2 {
    min-height: calc(600px + var(--ad-label-height));
}

.s1-device-desktop .s1-placeholder-type-BANNER,
.s1-device-mobile .s1-placeholder-type-INPAGE,
.s1-device-mobile .s1-placeholder-type-SUPERBANNER {
    display: none;
}

.s1-device-desktop .s1-debug-ad-name-banner:nth-of-type(2) {
    margin-block-start: 12px;

    @media (min-width: 1024px) {
        margin-bottom: 36px;
        margin-top: 0;
    }
}

.s1-device-desktop .s1-ad-name-posterad  {
    @media (min-width: 1024px) {
        margin-bottom: 36px;
        margin-top: 0;
    }
}

/* The first BANNER (topmobile) for MEW is campaign-dependent, so it should not have a minimum height */
.s1-device-mobile .s1-ad-mobile-id-topmobile,
.s1-ad-name-topmobile.s1-ad-topmobile {
    min-height: 0;
    margin-bottom: 0
}

.s1-ad-teaser {
    min-height: 100%;
    min-width: 100%;
}